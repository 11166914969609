<template>
<div>
  <GmapMap ref="googleMap" :center="center" :zoom="zoom" id="map" v-if="isMounted">
    <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position" :clickable="true" :draggable="false" :icon="m.markerOptions" @click="toggleInfoWindow(m, m.id)" @mouseover="toggleOver(m, m.id)">
      <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen = false">
        <div v-html="infoContent"></div>
      </gmap-info-window>
    </GmapMarker>
  </GmapMap>
</div>
</template>

<script>
import {
  isCSR
} from '@/utils'
if (isCSR()) {
  require('@/plugins/vue-google-map')
}
export default {
  props: {
    value: {
      type: Object,
      default: null
    },
    zoom: {
      type: Number,
      default: 18
    },
    typeSelect: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isMounted: false,
      center: {
        lat: 22.193913,
        lng: 113.539795
      },
      markers: [
        // {
        //   type: 3,
        //   address: '',
        //   range: 0,
        //   position: { lat: 22.193913, lng: 113.539795 },
        //   markerOptions: {
        //     url: require('@/images/map_icon3.png'),
        //     size: { width: 35, height: 76, f: 'px', b: 'px' },
        //     scaledSize: { width: 35, height: 38, f: 'px', b: 'px' }
        //   }
        // }
      ],
      currentMidx: 0,
      infoContent: '',
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoWinOpen: false,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -15
        }
      }
    }
  },
  watch: {
    'value.center': {
      handler(n, o) {
        if (o) {
          if (this.value) {
            this.init()
          }
        }
        console.log(123, n, o)
      },
      immediate: true
    },
    'value.markers': {
      handler(n, o) {
        // this.handleMarker()
        if (this.value) {
          this.init()
        }
        console.log(999, this.markers)
      },
      immediate: true
    }
  },
  mounted() {
    this.isMounted = true

    // this.$refs['googleMap'].$mapPromise.then((map) => {
    // this.maps = new google.maps.Map(document.getElementById("map"), {
    //     zoom: 13,
    //    //地图中心点，这里我以第一个数据的经纬度来设置中心点
    //     center: { lat: 1, lng: 1 },
    //     disableDefaultUI: false,
    //     zoomControl: false
    //   })
    // })
    let center
    if (this.value) {
      this.center = this.value.center
      this.markers = this.value.markers
    }
    // this.$refs['googleMap'].$mapPromise.then((map) => {
    //     const bounds = new google.maps.LatLngBounds()
    //     for (let m of this.markers) {
    //       bounds.extend(m.position)
    //     }
    //     map.fitBounds(bounds);
    //   });
  },
  methods: {
    init() {
      this.center = this.value.center
      this.markers = this.value.markers
      if (this.markers) {
        // this.markers.forEach(e => {
        //   e.position.lat = Number(e.position.lat)
        //   e.position.lng = Number(e.position.lng)
        // })
        let icon = ''
        this.markers = this.markers.map(e => {
          if (this.typeSelect) {
            try {
              icon = require(`@/assets/images/map_icon${this.typeSelect}.png`)
            } catch {
              icon = require(`@/assets/images/map_icon3.png`)
            }
          } else {
            try {
              icon = require(`@/assets/images/map_icon${e.type[0]}.png`)
            } catch {
              icon = require(`@/assets/images/map_icon3.png`)
            }
          }
          return {
            ...e,
            markerOptions: {
              url: icon,
              size: {
                width: 35,
                height: 38,
                f: 'px',
                b: 'px'
              },
              scaledSize: {
                width: 35,
                height: 38,
                f: 'px',
                b: 'px'
              }
            }
          }
        })
      }
    },
    toggleOver(marker, idx) {
      console.log('toover', marker, idx)
    },
    toggleInfoWindow(marker, idx) {
      // this.infoWindowPos = marker.position
      this.$emit('handleUpdate', marker, 20)
      // this.infoContent = this.getInfoWindowContent(marker)

      //check if its the same marker that was selected if yes toggle
      console.log(1234, marker, idx, this.currentMidx)

      // if (this.currentMidx == idx) {
      //   this.infoWinOpen = !this.infoWinOpen
      // }
      // //if different marker set infowindow to open and reset current marker index
      // else {
      //   this.infoWinOpen = true
      //   this.currentMidx = idx
      // }
    },
    getInfoWindowContent(marker) {
      return `<div>'${marker.content}</div>`
      //     return `<div class="card">
      //   <div class="card-image">
      //     <figure class="image is-4by3">
      //       <img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image">
      //     </figure>
      //   </div>
      //   <div class="card-content">
      //     <div class="media">
      //       <div class="media-content">
      //         <p class="title is-4">${marker.address}</p>
      //       </div>
      //     </div>
      //     <div class="content">
      //       ${marker.description}
      //       <br>
      //       <time datetime="2016-1-1">${marker.date_build}</time>
      //     </div>
      //   </div>
      // </div>`
    },
    handleMap() {
      this.$refs['googleMap'].$mapPromise.then(map => {
        map.panTo({
          lat: this.value.center.lat,
          lng: this.value.center.lng
        })
        map.InfoWindow({
          content: '123123123'
        })
      })
    },
    handleMap2() {
      console.log(1234234)
      this.$refs['googleMap'].$mapPromise.then(map => {
        map.panTo({
          lat: this.value.center.lat,
          lng: this.value.center.lng
        })
        map.InfoWindow({
          content: '123123123'
        })
      })
    },
    handleMarker() {
      this.$refs['googleMap'].$mapPromise.then(map => {
        map.panTo({
          lat: this.value.center.lat,
          lng: this.value.center.lng
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#map {
  width: 100%;
  height: 685px;
}

@media screen and (max-width: 767px) {
  #map {
    height: 300px;
  }
}
</style>
