<template>
  <div>
    <div class="jumbotron location-search">
      <b-container class="mt-4">
        <h1 class="my-4">{{ title }}</h1>
        <div class="d-flex flex-wrap">
          <div class="bar col-12 col-md-4">
            <div class="card">
              <h2>{{ $t('選擇地區') }}</h2>
              <b-form-group class="col-12 mr-md-1 px-0">
                <b-form-select v-model="area" :options="areaOption" name="area" sizes="md"></b-form-select>
              </b-form-group>
            </div>
            <div class="mt-4">
              <div class="card">
                <h2>{{ $t('類型') }}</h2>
                <!--<b-checkbox-group v-model="typeSelect">
              <ul class="d-flex flex-wrap flex-column justify-content-around">
                <li v-for="option in typeOption" :key="option.id">
                  <b-checkbox name="type" :value="option.id">{{option.label}}</b-checkbox>
                </li>
              </ul>
            </b-checkbox-group>-->
                <b-form-group class="col-12 mr-md-1 px-0">
                  <b-form-select v-model="typeSelect" :options="typeOption" name="area" sizes="md"></b-form-select>
                </b-form-group>
              </div>
            </div>
            <div class="list-m mt-5" v-if="listShow">
              <ul class="mx-0 px-3" v-if="!toDetail">
                <li
                  @click="mapToCenter(box)"
                  v-for="box in value.markers"
                  :key="box.id"
                  v-b-toggle="`collapse-${box.id}}`"
                  class="pointer"
                >
                  <!-- <p>{{ `${box.range}公里` }}</p> -->
                  <div class="blue">
                    <h3 class="title right-icon blue">{{ `${box.title}` }}</h3>
                  </div>
                  <p v-html="box.address"></p>
                  <b-collapse :id="`collapse-${box.id}}`" class="mt-2">
                    <b-card>
                      <div v-html="box.content"></div>
                    </b-card>
                  </b-collapse>
                </li>
              </ul>
              <div v-else>
                <a rel="" class="py-3 back mx-3" href="javascript:;" @click="handleBack">{{ $t('回到搜尋結果') }}</a>
                <div class="p-3">
                  <div class="blue">
                    <h3 class="title blue">{{ `${toDetailNo.title}` }}</h3>
                  </div>
                  <p>{{ `${toDetailNo.address}` }}</p>
                  <div v-html="toDetailNo.content" class="my-3"></div>
                </div>
                <ul class="type-list d-flex flex-wrap my-4 mx-3">
                  <li v-for="type in toDetailNo.type" :key="type">
                    <em :class="`icon icon-${type}`">{{ handleTypeName(type) }}</em>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-8">
            <ul class="box-list mx-0 d-flex flex-wrap" v-if="selection">
              <li v-for="flagType in selection['branch-bank-type']" :key="flagType.id">
                <em class="icon col-auto" :title="flagType.label"></em>
                <div>{{ flagType.label }}</div>
              </li>
            </ul>
            <GoogleMap
              class="my-3"
              :zoom="zoomNo"
              :typeSelect="typeSelect"
              v-model="value"
              @handleUpdate="handleUpdate"
              v-if="isMounted"
            />
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import updateSeoInfo from '@/utils/meta'

import Lifecycle from '@/mixins/lifecycle'
import GoogleMap from '@/components/googleMaps'

export default {
  name: 'google-map',
  components: {
    GoogleMap
  },
  data() {
    return {
      isMounted: false,
      center: null,
      toDetail: false,
      toDetailNo: '',
      zoomNo: 13,
      typeSelect: null,
      typeOption: [
        {
          text: this.$t('所有類型'),
          value: null,
          notEnabled: true
        }
      ],
      ssrShow: false,
      listShow: false,
      value: {
        center: {
          lat: 22.193913,
          lng: 113.539795
        },
        markers: [
          {
            //  type: 1,
            // address: '',
            // range: 0,
            // position: { lat: 22.193913, lng: 113.539895 },
          }
        ]
      },
      areaOption: [
        {
          text: this.$t('所有地區'),
          value: null,
          notEnabled: true
        }
      ],
      area: null,
      service: null,
      dataAll: [],
      title: null
    }
  },
  mixins: [Lifecycle],
  computed: {
    ...mapState('bank', ['areas', 'types', 'banks']),
    ...mapState('common', ['page', 'selection'])
  },
  seoInfo() {
    try {
      return {
        title: this.page.metaTitle,
        description: this.page.metaDescription || this.page.metaTitle,
        keywords: this.page.metaKeywords || this.page.metaTitle,
        metas: this.page.metaExtras
      }
    } catch (err) {}
  },
  serverPrefetch() {
    0
    return this.fetchData()
  },
  beforeMount() {},
  mounted() {
    this.isMounted = true
    const alreadyInited = !!this.areas
    // console.log('alreadyInited', alreadyInited)
    this.fetchData()
    // console.log('bank', this.areas, this.types, this.banks)
  },
  watch: {
    typeSelect(n, o) {
      // this.handleType()
      this.handleBank()
      // }
    },
    area(n, o) {
      // this.handleType()
      // if(this.area){
      this.handleBank()
      // }
    }
  },
  methods: {
    fetchData() {
      let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
      path = path.replace(`preview/`, '')
      const cookies = this.$ssrContext && this.$ssrContext.cookies
      const { preview, id, vid } = this.$route.query
      return new Promise((resolve, reject) => {
        Promise.all([
          this.fetchInitData(),
          this.$store.dispatch('common/FETCH_PAGE', {
            path,
            cookies,
            preview,
            id
          }),
          this.$store.dispatch('bank/FETCH_AREAS', {
            cookies
          }),
          this.$store.dispatch('bank/FETCH_TYPES', {
            cookies
          }),
          this.$store.dispatch('bank/FETCH_BANKS', {
            cookies,
            preview,
            id,
            vid
          }),
          this.$store.dispatch('common/FETCH_SELECTION', {
            type: 'branch-bank-type'
          })
        ])
          .then(() => {
            this.hanldeInit()
            resolve(updateSeoInfo.call(this))
          })
          .catch(reject)
      })
    },
    async hanldeInit() {
      const { preview } = this.$route.query
      if (!preview) {
        // 预览中不需要出现地区和类型选择
        this.areaOption.push(
          ...this.areas.map(e => {
            return {
              text: e.label,
              value: e.id
            }
          })
        )
        this.typeOption.push(
          ...this.types.map(e => {
            return {
              text: e.label,
              value: e.id
            }
          })
        )
      }
      if (await this.banks) {
        console.log('object', this.banks)
        this.value.markers = this.banks
        this.getBank()
        if (this.page) this.title = this.page.title
      }
    },
    handleTypeName(num) {
      return this.types.filter(e => e.id == num)[0].label
    },
    handleUpdate(val, zoom) {
      // console.log('nn', val)
      this.toDetail = true
      this.toDetailNo = val
      this.zoomNo = zoom
      this.value.center = {
        lat: val.position.lat,
        lng: val.position.lng
      }
      // this.$emit('uploadMap', val)
    },
    mapToCenter(box) {
      this.toDetail = true
      this.toDetailNo = box
      this.zoomNo = 20
      this.value.center = {
        lat: box.position.lat,
        lng: box.position.lng
      }
    },
    handleType() {
      // console.log(this.value.markers.filter(e => e.type == this.typeSelect))
      this.value.markers = this.value.markers.filter(e => e.type == this.typeSelect)
    },
    handleBank() {
      const typeArr = this.dataAll.filter(e => {
        if (this.typeSelect) {
          if (e.type.length > 1) {
            return e.type.indexOf(String(this.typeSelect)) > -1
          } else {
            return e.type[0] == this.typeSelect
          }
        } else {
          return e
        }
      })

      const areaArr = typeArr.filter(e => {
        if (this.area) {
          return e.area == this.area
        } else {
          return e
        }
      })
      this.value.markers = areaArr
      this.value.center = this.value.markers[0].position
      this.zoomNo = 16
      if (!this.area) {
        this.zoomNo = 13
      }
      // console.log(2222, this.value.markers)
    },
    getBank() {
      this.dataAll = this.banks
      this.value.markers = this.dataAll
      this.listShow = true
    },
    handleBack() {
      this.toDetail = !this.toDetail
      this.zoomNo = 16
    }
  }
}
</script>
